/* Full-width container for the header */
.full-width-container {
  width: 100%;
  padding: 0;
  margin: 0;
}

.row.no-gutters {
  margin: 0;
  padding: 0;
}

.wpo-site-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 999;
  padding: 10px 15px; /* Compact padding */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.wpo-site-header.scrolled {
  background-color: #003366;
  padding: 5px 10px; /* Reduced padding when scrolled */
}

/* Prevent overlap with content below */
.header-spacer {
  height: 70px; /* Match header height */
  width: 100%;
}

/* Navbar styles */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.navbar-nav {
  display: flex;
  gap: 20px; /* Space between menu items */
  align-items: center;
  flex-wrap: nowrap; /* Prevent wrapping */
}

.navbar-nav li {
  list-style: none;
  position: relative; /* Needed for submenu positioning */
}

.navbar-nav .nav-link {
  padding: 5px 10px;
  font-size: 14px;
  white-space: nowrap; /* Prevent text wrapping */
  color: #333;
  text-decoration: none;
}

.navbar-nav .nav-link:hover {
  color: #003366;
}

/* Submenu styles */
.sub-menu {
  position: absolute;
  top: 100%; /* Position submenu below the parent */
  left: 0;
  display: none; /* Hidden by default */
  background: white;
  list-style: none;
  padding: 10px 0;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 200px; /* Ensure consistent width for submenus */
}

.menu-item-has-children:hover > .sub-menu {
  display: block; /* Show submenu on hover */
}

.sub-menu li {
  padding: 5px 10px;
}

.sub-menu .nav-link {
  color: #333;
  font-size: 13px;
}

.sub-menu .nav-link:hover {
  color: #003366;
}

/* Multi-level submenu */
.sub-sub-menu {
  position: absolute;
  top: 0;
  left: 100%; /* Align next to parent menu */
  display: none;
  background: white;
  list-style: none;
  padding: 10px 0;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 200px;
}

.sub-menu li:hover > .sub-sub-menu {
  display: block; /* Show sub-submenu on hover */
}

/* Logo styles */
.navbar-header img {
  max-width: 120px;
  height: auto;
  display: block;
  margin-top: 0;
}

/* Adjust logo for Arabic (RTL) */
.rtl .navbar-header img {
  margin-top: -10px; /* Lift logo up slightly for Arabic */
  margin-right: 20px; /* Add some space between logo and edge */
}

/* Language Buttons */
.btn {
  font-size: 12px;
  padding: 4px 8px;
  margin: 0 5px;
  white-space: nowrap; /* Prevent wrapping */
}

.btn:hover {
  background-color: #003366;
  color: white;
  border-color: #003366;
}

/* RTL Adjustments */
.rtl-nav {
  justify-content: flex-end;
}

.rtl .nav-link {
  text-align: right;
}

.rtl .sub-menu {
  left: auto;
  right: 0;
}

.rtl .sub-sub-menu {
  left: auto;
  right: 100%;
}

/* Prevent navbar items from breaking */
.navbar-header,
.navbar-nav,
.btn {
  flex-shrink: 0; /* Prevent shrinking */
}
/* Header.css */

/* Base submenu styles */
.sub-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: auto;
  min-width: 200px;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: none;
}

/* RTL submenu positioning */
[dir="rtl"] .sub-menu {
  left: auto !important;
  right: 0 !important;
}

/* Sub-submenu positioning */
.sub-sub-menu {
  position: absolute;
  top: 0;
  left: 100%;
  right: auto;
}

[dir="rtl"] .sub-sub-menu {
  left: auto !important;
  right: 100% !important;
}

/* Hover states */
.menu-item-has-children:hover > .sub-menu {
  display: block;
}

.sub-menu li:hover > .sub-sub-menu {
  display: block;
}
.wpo-site-header {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 9999 !important;
}


/* Logo specific RTL alignment */
[dir="rtl"] .navbar-header {
  text-align: right; /* Align logo to the right */
}

[dir="rtl"] .navbar-header img {
  float: right;
}
/* RTL alignment adjustments for navbar */
[dir="rtl"] .navigation-holder {
  justify-content: flex-end; /* Align the navigation to the right */
  padding-left: 240px; /* Add padding from the right */
}
[dir="ltr"] .navigation-holder {
  justify-content: flex-end; /* Align the navigation to the right */
  padding-right: 240px; /* Add padding from the right */
}

[dir="rtl"] .navbar-nav {
  justify-content: flex-end; /* Align menu items to the right */
  padding-right: 40px; /* Add some spacing for better positioning */
}

[dir="rtl"] .navbar-nav li {
  margin-left: 0; /* Reset margin for RTL */
  margin-right: 15px; /* Add spacing between items */
}
