.training-section {
    background-color: #ffffff; /* Sets the background to white */
    padding: 20px 0; /* Optional: adds some padding */
    margin-bottom: 40px; /* Adds space below the component */

}

.training-cover {
    text-align: center;
}
.training-cover img {
    max-width: 80%; /* Adjust the size of the image */
    height: auto;   /* Maintain aspect ratio */
    border-radius: 10px; /* Optional rounded corners */
    width: 40%;
    max-width: 300px;
    margin-bottom: 20px;
}

.training-cover h2 {
    font-size: 24px;
    color: #333;
    font-weight: 600;
}

.training-cover h2 span {
    color: #d9232d; /* Accent color for the year */
}

.training-programs {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: left;
}

/* Ensure row aligns content to the left */
.training-section .row {
    justify-content: flex-start !important; /* Force alignment to the left */
}

.training-section .container-fluid {
    max-width: 90%; /* Reduce the max width to make it move further left */
    margin-left: 0; /* Force container to stick to the left */
}

.training-programs h3 {
    font-size: 28px;
    margin-bottom: 20px;
}

.program-list {
    list-style: none;
    padding: 0;
}

.program-item {
    display: flex;
    justify-content: space-between;
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
}

.program-title {
    flex: 2;
    font-size: 16px;
    color: #333;
}

.program-location,
.program-date {
    flex: 1;
    text-align: right;
    color: #777;
}
