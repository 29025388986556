.all-clients-section {
    padding: 50px 0;
    background: #f9f9f9;
}

.section-title {
    text-align: center;
    margin-bottom: 30px;
}

.clients-title {
    font-size: 2rem;
    color: #003366;
    font-weight: bold;
    margin-bottom: 10px;
}

.clients-subtitle {
    font-size: 1.25rem;
    color: #666;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.5;
}

.slider-item {
    padding: 10px;
    text-align: center;
}

.slider-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.slider-item:hover .slider-image {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.slider-title {
    margin-top: 10px;
    font-size: 14px;
    color: #555;
    font-weight: bold;
    text-align: center;
    direction: ltr;
}

.custom-arrow {
    background: linear-gradient(135deg, #007bff, #0056b3);
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
    cursor: pointer;
    transition: transform 0.3s ease, background 0.3s ease;
}

.custom-arrow.next-arrow {
    right: -25px;
}

.custom-arrow.prev-arrow {
    left: -25px;
}

.custom-arrow:hover {
    transform: scale(1.1);
    background: linear-gradient(135deg, #0056b3, #003f7f);
}

[dir="rtl"] .slick-list {
    direction: ltr;
}

[dir="rtl"] .slick-slide {
    direction: rtl;
}

[dir="rtl"] .next-arrow {
    left: -25px !important;
    right: auto !important;
}

[dir="rtl"] .prev-arrow {
    right: -25px !important;
    left: auto !important;
}

.slick-track {
    display: flex;
    align-items: center;
}

.slick-slide {
    float: none;
    height: auto;
}

@media (max-width: 768px) {
    .slider-image {
        width: 90%;
        margin: 0 auto;
    }
    .custom-arrow {
        width: 30px;
        height: 30px;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .clients-title {
        font-size: 1.5rem;
    }
    .custom-arrow {
        width: 25px;
        height: 25px;
        font-size: 12px;
    }
}