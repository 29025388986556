/* Center the video section on the page */
.video-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background-color: #f9f9f9; /* Optional: a light background for visual appeal */
  }
  
  /* Increase the container size for a larger video player */
  .video-container {
    max-width: 1000px; /* Increase as needed */
    width: 90%;
    margin: 0 auto;
  }
  
  /* Styling for the video element */
  .cultural-video {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }
  