.media-gallery-section {
  padding: 40px 0;
  background-color: #f9f9f9;
}

.gallery-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 20px;
  padding: 20px 0;
}

.gallery-header h2 {
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
}

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-wrap: nowrap;
}

.gallery-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  max-width: 1100px;
  transform-style: preserve-3d;
}

.gallery-item {
  width: calc(33.33% - 10px);
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
  transition: transform 0.3s ease;
  background-color: transparent;
  position: relative;
}

.gallery-item:hover {
  transform: scale(1.05);
}

.lazy-image-container {
  position: relative;
  overflow: hidden;
  background: #f0f0f0;
  width: 100%;
  height: 100%;
}

.image-placeholder {
  width: 100%;
  height: 100%;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

.gallery-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  will-change: transform;
  transform: translateZ(0);
}

@media (max-width: 768px) {
  .gallery-item {
    width: calc(50% - 10px);
  }
}

@media (max-width: 480px) {
  .gallery-item {
    width: 100%;
  }
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  outline: none;
}

.lightbox-content {
  max-width: 90%;
  max-height: 80%;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
  z-index: 1001;
  transition: transform 0.2s ease;
}

.close:hover {
  transform: scale(1.2);
}

.fade-in {
  opacity: 1;
  transition: opacity 0.2s ease;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.2s ease;
}

.arrow-btn {
  background: linear-gradient(145deg, #003366, #004080);
  color: #fff;
  border: none;
  padding: 12px 24px;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
  white-space: nowrap;
}

.arrow-btn:hover {
  background: linear-gradient(145deg, #002244, #003366);
}

.arrow-btn:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}